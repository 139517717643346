<template>
  <div class="overflow-hidden">
    <transition
      :css="false"
      @enter="enter"
      @leave="leave"
    >
      <h2 v-for="(headline, i) in headlines" :key="i" class="absolute top-0 left-0 serif f4 f3-ns f1-l lh-title w-100 white">
        {{ headline }}
      </h2>
    </transition>
  </div>
</template>

<script>
import anime from 'animejs'
import s from '../store'

export default {
  name: 'TextFader',
  props: {
    els: { type: String, default: '[]' }
  },
  data () {
    return {
      privateState: {
        els: JSON.parse(this.els),
        activeEl: 0
      },
      sharedState: s.state
    }
  },
  computed: {
    headlines () {
      return this.privateState.els.filter((headline, i) =>
        i === this.privateState.activeEl
      )
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.updateActiveEl()
    })
  },
  methods: {
    updateActiveEl: function () {
      setInterval(() => {
        if (this.privateState.activeEl < this.privateState.els.length - 1) {
          this.privateState.activeEl++
        } else {
          this.privateState.activeEl = 0
        }
      }, 3500)
    },
    enter: (el, done) => {
      anime({
        targets: el,
        opacity: [0, 1],
        duration: 800,
        easing: 'easeOutCubic',
        complete: done
      })
    },
    leave: (el, done) => {
      anime({
        targets: el,
        opacity: 0,
        duration: 800,
        easing: 'easeOutCubic',
        complete: done
      })
    }
  }
}
</script>
