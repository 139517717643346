<template>
  <img
    :data-src="src"
    :alt="title"
    :class="customClass"
    loading="lazy"
  >
</template>

<script>
export default {
  name: 'ProductImage',
  props: {
    src: {
      type: String,
      required: true
    },
    srcset: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      default: 'v-mid mw-none w-100 product__image'
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    sizes: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      observer: null
    }
  },
  beforeDestroy () {
    this.observer.disconnect()
  },
  created () {
    this.observer = new IntersectionObserver(this.handleScroll, {
      root: this.$el,
      rootMargin: '0px',
      threshold: 0.1
    })
  },
  mounted () {
    this.observer.observe(this.$el)
    if (this.width !== '') {
      this.$el.setAttribute('width', this.width)
    }
    if (this.height !== '') {
      this.$el.setAttribute('height', this.height)
    }
    if (this.sizes !== '') {
      this.$el.setAttribute('sizes', this.sizes)
    }
    if (this.image !== '') {
      this.$el.setAttribute('data-image', this.image)
    }
    this.slider = window.innerWidth < 1024
  },
  methods: {
    handleScroll (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (this.slider) {
            if (this.srcset !== '') {
              this.$el.setAttribute('data-flickity-lazyload-srcset', this.srcset)
            } else {
              this.$el.setAttribute('data-flickity-lazyload', this.src)
            }
          } else {
            this.$el.setAttribute('src', this.src)
            if (this.srcset !== '') {
              this.$el.setAttribute('srcset', this.srcset)
            }
          }
          this.observer.unobserve(entry.target)
        }
      })
    }
  }
}
</script>
