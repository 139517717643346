import 'flickity-imagesloaded'
import Flickity from 'flickity'

let productImages
let prevEl
let nextEl

const sliderInit = () => {
  // productImages.forEach((element) => {
  const slider = new Flickity(productImages, {
    imagesLoaded: true,
    prevNextButtons: false,
    pageDots: true,
    autoHeight: true,
    cellSelector: '.product__images-item_shown',
    lazyLoad: true
  })

  prevEl.addEventListener('click', () => slider.previous())
  nextEl.addEventListener('click', () => slider.next())

  // fix for iOS
  slider.on('dragStart', () => (document.ontouchmove = () => false))
  slider.on('dragEnd', () => (document.ontouchmove = () => true))
  // });

  const sliderWrapper = document.getElementsByClassName('product__images')[0]
  if (sliderWrapper !== undefined) {
    sliderWrapper.style.overflow = 'visible'
  }
}

const init = () => {
  productImages = document.querySelector('.product__color-images_shown')
  prevEl = document.querySelector('.js-prev')
  nextEl = document.querySelector('.js-next')

  if (productImages && window.innerWidth < 1024) {
    sliderInit()
  }
}

export const reload = () => {
  new Flickity('.product__color-images_shown').destroy()
  setTimeout(() => {
    init()
  }, 0)
}

export default {
  init
}
