<template>
  <div class="overflow-hidden">
    <div
      v-for="(image, i) in privateState.els"
      :key="i"
      class="absolute z-0 absolute--fill cover bg-center"
      :style="{ backgroundImage: `url(${image})`, opacity: 0.1 }"
    />

    <transition-group
      :css="false"
      @enter="enter"
      @leave="leave"
    >
      <picture
        v-for="(image, i) in privateState.els"
        v-show="i === privateState.activeEl"
        :key="`key-${i}`"
        class=""
      >
        <source media="(max-width: 560px)" :srcset="image.mobile">
        <source media="(max-width: 1024px)" :srcset="image.tablet">
        <img
          class="absolute z-0 absolute--fill fit-cover h-100 w-100"
          :src="image.desktop"
          :alt="image.alt"
        >
      </picture>
    </transition-group>
  </div>
</template>

<script>
import anime from 'animejs'
import s from '../store'

export default {
  name: 'BackgroundImageFader',
  props: {
    els: { type: String, default: '[]' }
  },
  data () {
    return {
      privateState: {
        els: JSON.parse(this.els),
        list: [],
        activeEl: 0
      },
      image: { type: String, default: '[]' },
      sharedState: s.state
    }
  },
  computed: {},
  created () {
    this.privateState.list = [...this.privateState.els]
    this.privateState.els = []
    this.privateState.els.push(this.privateState.list[this.privateState.activeEl])
  },
  mounted () {
    this.$nextTick(() => {
      this.updateActiveEl()
      if (s.debug) console.log('privateState.els', this.privateState.els)
    })
  },
  methods: {
    updateActiveEl: function () {
      setInterval(() => {
        if (this.privateState.activeEl < this.privateState.list.length - 1) {
          this.privateState.activeEl++
        } else {
          this.privateState.activeEl = 0
        }
        if (this.privateState.els.length < this.privateState.list.length) {
          this.privateState.els.push(this.privateState.list[this.privateState.activeEl])
        }
      }, 3500)
    },
    enter: (el, done) => {
      anime({
        targets: el,
        opacity: [0, 1],
        duration: 800,
        easing: 'easeOutCubic',
        complete: done
      })
    },
    leave: (el, done) => {
      anime({
        targets: el,
        opacity: 0,
        duration: 800,
        easing: 'easeOutCubic',
        complete: done
      })
    }
  }
}
</script>
